import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Page } from '../../global';
import uuid from 'react-uuid';
import { Paper, Typography } from '@mui/material';

export interface CustomNavLinkProps {
    page: Page
}

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ page }) => {
    const theme = useTheme(); // Access the current theme

    return (
        <NavLink
            key={uuid()}
            to={page.path}
            style={({ isActive }) => ({
                color: isActive
                    ? theme.palette.primary.main // Active link color
                    : theme.palette.text.primary, // Inactive link color
                textDecoration: 'none',
                padding: '3px 10px',
                borderRadius: '4px',
                backgroundColor: isActive ? theme.palette.action.selected : 'transparent',
                display: "flex",
                alignItems: "center",
                gap: 5,
                boxShadow: isActive ? '0 3px 8px #0000001f,0 3px 1px #0000000a' : 'none'
            })}
        >
            <img src={page.icon} alt={`${page.title} icon`} width="24" height="24" />
            <Typography fontWeight={600}>{page.title}</Typography>
        </NavLink>
    );
};

export default CustomNavLink;