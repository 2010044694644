import { IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import CustomSearchTextField from "../components/shared/custom-search-textfield";
import CustomAddButton from "../components/shared/custom-add-button";
import CustomDataGrid from "../components/shared/custom-data-grid";
import { GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDialog } from "../provider/dialog-provider";
import { useEffect, useState } from "react";
import { useService } from "../provider/service-provider";
import { Stock } from "../models/stock.model";
import { NewStock } from "../models/new-stock.model";
import { UpdatedStock } from "../models/updated-stock.model";
import AddNewStock from "../components/stock-management/add-new-stock";
import UpdateStock from "../components/stock-management/update-stock";
import FilterBar from "../components/stock-management/filter-bar";
import CustomConfirmation from "../components/shared/custom-confimation";
import { StockFilter } from "../models/stock-filter.model";
import { VehiclePart } from "../models/vehicle-part";
import AppsIcon from '@mui/icons-material/Apps';
import AlternativeModels from "../components/stock-management/alternative-models";
import { Role } from "../enums/role";

const defaultPaginationModel = {
	page: 0,
	pageSize: 10,
}
const StockManagement = () => {

	const { stockService, vehiclePartService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [isScrapingData, setIsScrapingData] = useState(false);

	const [sortModel, setSortModel] = useState<GridSortModel>([]);
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(defaultPaginationModel);
	const [rowCount, setRowCount] = useState(0)
	const [stockFilter, setStockFilter] = useState({
		searchValue: null,
		brandId: null,
		modelId: null,
		categoryId: null,
		shelfId: null,
		branchId: null
	});

	const stockColumns: GridColDef[] = [
		{ field: 'productCode', headerName: "Stok Kodu", width: 170 },
		{ field: 'brand', headerName: "Marka", width: 100 },
		{ field: 'model', headerName: "Model", flex: 1 },
		{ field: 'productName', headerName: "Stok Adı", flex: 2 },
		{ field: 'category', headerName: "Kategori", width: 100 },
		{ field: 'quantityInStock', headerName: "Miktar", width: 100 },
		{ field: 'shelf', headerName: "Raf", width: 110 },
		{ field: 'branch', headerName: "Şube", flex: 1 },
		{ field: 'purchasePrice', headerName: "Birim Fiyat", width: 100 },
		{
			field: "actions", headerName: "", width: 100,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					<IconButton
						color="primary"
						onClick={() => openAlternativeModelsDialog(params.row.productCode)}
					>
						<AppsIcon />
					</IconButton>
					<IconButton
						color="primary"
						onClick={() => openEditStockDialog(params.row)}
					>
						<EditIcon />
					</IconButton>
					{localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							color="error"
							onClick={() => openConfirmationDialog(params.row.id)}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)}
				</Stack>
			),
		}
	];

	const scrapingColumns: GridColDef[] = [
		{ field: 'productCode', headerName: "Stok Kodu", width: 150 },
		{ field: 'brand', headerName: "Marka", width: 150 },
		{ field: 'model', headerName: "Model", width: 150 },
		{ field: 'productName', headerName: "Stok Adı", flex: 1 },
		{
			field: "actions", headerName: "", width: 50,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					<IconButton
						color="primary"
						onClick={() => openAlternativeModelsDialog(params.row.productCode)}
					>
						<AppsIcon />
					</IconButton>
				</Stack>
			),
		}
	];

	const [columns, setColumns] = useState(stockColumns);
	const [stocks, setStocks] = useState<Stock[] | VehiclePart[]>([]);

	const resetPagination = () => {
		setPaginationModel(defaultPaginationModel);
	}

	const getFilteredStocks = () => {

		const sortField = sortModel.length ? sortModel[0].field : null;
		const sortOrder = sortModel.length && sortModel[0].sort ? sortModel[0].sort : null;

		if (isScrapingData) {
			vehiclePartService.getAllVehicleParts({ ...paginationModel, ...stockFilter, sortField, sortOrder }).then((res) => {
				setColumns(scrapingColumns);
				setStocks(res.content);
				setRowCount(res.totalElements);
			})
		} else {
			stockService.getFilteredStocks({ ...paginationModel, ...stockFilter, sortField, sortOrder}).then((res) => {
				setColumns(stockColumns);
				setStocks(res.content);
				setRowCount(res.totalElements);
			})
		}


	}

	useEffect(getFilteredStocks, [sortModel, paginationModel]);

	const createStock = (newStock: NewStock) => {
		stockService.createStock(newStock).then(() => {
			handleCloseDialog();
			getFilteredStocks();
		});
	}

	const updateStock = (updatedStock: UpdatedStock) => {
		stockService.updateStock(updatedStock).then(() => {
			handleCloseDialog();
			getFilteredStocks();
		});
	}

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteStock(id)} />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const deleteStock = (id: number) => {
		stockService.deleteStock(id).then(() => {
			handleCloseDialog();
			getFilteredStocks();
		})
	}

	const openNewStockDialog = () => {
		handleOpenDialog(<AddNewStock handleSave={createStock} />, {
			headerTitle: "Yeni Stok Kaydı Ekle",
			haveCloseIcon: true
		});
	}

	const openEditStockDialog = (stock: Stock) => {
		handleOpenDialog(<UpdateStock handleSave={updateStock} stock={stock} />, {
			headerTitle: "Stok Kaydı Düzenle",
			haveCloseIcon: true
		});
	}

	const setFieldStockFilter = (fieldName: keyof StockFilter, value: string | number) => {
		setStockFilter(prev => ({ ...prev, [fieldName]: value }));
	}

	const openAlternativeModelsDialog = (productCode: string) => {
		handleOpenDialog(<AlternativeModels productCode={productCode} />, {
			headerTitle: "Alternatif Modeller",
			haveCloseIcon: true
		});
	}

	return (
		<Stack>
			<PageHeader title="Stok Yönetimi" />
			<Stack p="10px" gap="20px">
				<Stack p="30px" gap="40px" borderRadius="20px" style={{borderWidth: "1px", borderStyle: "solid"}} flexDirection="row" justifyContent="space-between">
					<FilterBar handleClickSearch={() => { resetPagination(); getFilteredStocks(); }} isScrapingData={isScrapingData} setIsScrapingData={setIsScrapingData} stockFilter={stockFilter} setFieldStockFilter={setFieldStockFilter} />
					<CustomAddButton title="Yeni Kayıt Ekle" onClick={openNewStockDialog} />
				</Stack>
				<Stack p="30px" gap="40px" borderRadius="20px" style={{borderWidth: "1px", borderStyle: "solid"}}>
					<CustomDataGrid
						columns={columns}
						rows={stocks}
						disableColumnMenu
						disableColumnResize
						disableRowSelectionOnClick
						pageSizeOptions={[10]}
						rowCount={rowCount}
						paginationMode="server"
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default StockManagement;