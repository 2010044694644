import { createTheme } from "@mui/material/styles";

export const getTheme = (mode: "dark" | "light") => createTheme({
	palette: {
		mode,
		background: {
			default: mode == 'dark' ? '#121212' : '#E8EBED',
			paper: mode === "dark" ? "#1E1E1E" : "#FFFFFF",
		},
		primary: {
			main: mode === "dark" ? "#FFFFFF" : "#000000",
		},
		info: {
			main: mode === "dark" ? "#29B6F6" : "#064568",
		},
		success: {
			main: mode === "dark" ? "#4CAF50" : "#2e7d32",
		},
		text: {
			primary: mode === "dark" ? "#E0E0E0" : "#212121",
			secondary: mode === "dark" ? "#B0BEC5" : "#757575",
		},
		divider: mode === "dark" ? "#424242" : "#E0E0E0",
		action: {
			selected: mode === "dark" ? "#1E1E1E" : "#FFFFFF",
		}
	},
	typography: {
		fontSize: 13,
		fontFamily: 'Inter, Arial, sans-serif',
	},
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				inputRoot: {
					padding: "3px"
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					fontSize: "14px",
					backgroundColor: mode === "dark" ? "#1E1E1E" : "#FFFFFF",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					fontSize: "14px"
				},
				inputTypeSearch: {
					padding: '10.5px 14px 10.5px 0px'
				},
				input: {
					padding: '10.5px 14px 10.5px 14px'
				},
				multiline: {
					padding: '5.25px 7px 5.25px 7px'
				}
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					width: "100%",
					height: "100%"
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					color: mode === "dark" ? "#E0E0E0" : "#212121",
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					backgroundColor: mode === "dark" ? "#1E1E1E" : "#FFFFFF",
					"&:hover": {
						backgroundColor: mode === "dark" ? "#333333" : "#F5F5F5",
					},
				}
			}
		},
		MuiStack: {
			styleOverrides: {
				root: {
					borderColor: mode === "dark" ? "#424242" : "#E8EAEC",
				},
			},
		}
	},
});