import { Button, IconButton, Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import CustomSearchTextField from "../components/shared/custom-search-textfield";
import CustomDataGrid from "../components/shared/custom-data-grid";
import { useEffect, useState } from "react";
import { GridColDef, GridPaginationModel, GridSortModel, GridValueGetter } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import { useDialog } from "../provider/dialog-provider";
import ServiceFormDetail from "../components/service-management/service-form-detail";
import { ServiceForm } from "../models/service-form.model";
import { useService } from "../provider/service-provider";
import dayjs from "dayjs";
import { getServiceStatusColor, ServiceStatus } from "../enums/service-status.enum";
import SearchIcon from '@mui/icons-material/Search';
import { ServiceFormPart } from "../models/service-form-part";

const defaultPaginationModel = {
	page: 0,
	pageSize: 10,
}

const ServiceFormRecords = () => {

	const { serviceFormService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [searchValue, setSearchValue] = useState("");
	const [serviceForms, setServiceForms] = useState<ServiceForm[]>([]);

	const [sortModel, setSortModel] = useState<GridSortModel>([]);
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(defaultPaginationModel);
	const [rowCount, setRowCount] = useState(0)

	const getAllServiceForms = () => {

		const sortField = sortModel.length ? sortModel[0].field : null;
		const sortOrder = sortModel.length && sortModel[0].sort ? sortModel[0].sort : null;

		serviceFormService.getAllServiceFormRecords({ ...paginationModel, searchValue, sortField, sortOrder }).then((res) => {
			setServiceForms(res.content);
			setRowCount(res.totalElements);
		})
	}

	useEffect(getAllServiceForms, [sortModel, paginationModel]);

	const openDetailDialog = (serviceForm: ServiceForm) => {
		handleOpenDialog(<ServiceFormDetail serviceForm={serviceForm} />, {
			headerTitle: "Servis Formu Detayları",
			haveCloseIcon: true
		});
	}

	const getPartsCost = (parts: ServiceFormPart[]) => parts.reduce((acc, part) => {
		const quantity = part.quantity || 0; // null ise 0 yap
		const price = part.price || 0;       // null ise 0 yap
		return acc + (quantity * price);
	}, 0)

	const columns: GridColDef[] = [
		{
			field: 'serviceStatus', headerName: "Durum", flex: 0.8, renderCell: (params) => (
				<Stack height="100%" justifyContent="center">
					<Typography color={getServiceStatusColor(params.row.serviceStatus)}>{ServiceStatus[params.row.serviceStatus as keyof typeof ServiceStatus]}</Typography>
				</Stack>
			)
		},
		{
			field: 'fullName', headerName: "Müşter Ad/Ünvan", flex: 1, renderCell: (params) => (
				<>{params.row.customer.fullName}</>
			)
		},
		{
			field: 'licensePlate', headerName: "Plaka", flex: 1, renderCell: (params) => (
				<>{params.row.vehicle.licensePlate}</>
			)
		},
		{
			field: 'serviceDate', headerName: "Tarih", flex: 1, renderCell: (params) => (
				<>{dayjs(params.row.serviceDate).format("DD.MM.YYYY HH:mm")}</>
			)
		},
		{ field: 'mileage', headerName: "Kilometre", flex: 1 },
		{
			field: 'partsCost', headerName: "Parça Fiyatı", flex: 1, renderCell: (params) => (
				<>{getPartsCost(params.row.parts)}</>
			)
		},
		{ field: 'laborCost', headerName: "İşçilik Fiyatı", flex: 1 },
		{
			field: 'isWarrant', headerName: "Garanti Kapsamı", flex: 1, renderCell: (params) => (
				<>{params.row.isWarrant ? "Evet" : "Hayır"}</>
			)
		},
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					<IconButton
						color="primary"
						onClick={() => openDetailDialog(params.row)}
					>
						<InfoIcon />
					</IconButton>
				</Stack>
			),
		}
	];


	return (
		<Stack>
			<PageHeader title="Servis Form Kayıtları" />
			<Stack p="10px">
				<Stack p="30px" gap="30px" borderRadius="20px" style={{borderWidth: "1px", borderStyle: "solid"}}>
					<Typography fontSize="20px" fontWeight={600}>Tüm Servis Formu Kayıtları</Typography>
					<Stack flexDirection="row" gap={2}>
						<CustomSearchTextField searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Servis Formu Ara" />
						<Button
							startIcon={<SearchIcon />}
							sx={{ width: "120px" }}
							variant="contained"
							onClick={getAllServiceForms}
						>
							Ara
						</Button>
					</Stack>
					<CustomDataGrid
						columns={columns}
						rows={serviceForms}
						disableColumnMenu
						disableColumnResize
						disableRowSelectionOnClick
						pageSizeOptions={[10]}
						rowCount={rowCount}
						paginationMode="server"
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
					/>
				</Stack>
			</Stack>
		</Stack>
	);

}

export default ServiceFormRecords;