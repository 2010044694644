import { Stack, Divider, Typography, useColorScheme } from "@mui/material";
import { FC } from "react";
import CustomThemeSwitcher from "./custom-theme-switcher";

const PageHeader: FC<{ title: string }> = ({ title }) => {

	return (
		<>
			<Stack p="10px" display="flex" flexDirection="row" justifyContent="space-between" >
				<Typography fontSize="20px" fontWeight={600}>{title}</Typography>
				<CustomThemeSwitcher />
			</Stack>
			<Divider flexItem />
		</>
	);
};

export default PageHeader;