import React, { createContext, useMemo, useState, useContext, ReactNode, FC } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getTheme } from '../styles/theme';

interface ThemeContextType {
    mode: "light" | "dark"
    toggleColorMode: () => void
}

// Create Theme Context
const ThemeContext = createContext<ThemeContextType | null>(null);

const ThemeProviderWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<"light" | "dark">('light');

  // Function to toggle mode
  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(() => getTheme(mode), [mode]);


  return (
    <ThemeContext.Provider value={{ mode, toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeWrapper = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeWrapper must be used within a ThemeProviderWrapper');
    }
    return context;
};

export default ThemeProviderWrapper;