import { FC } from "react";
import { ServiceForm } from "../../models/service-form.model";
import { Button, Grid, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDialog } from "../../provider/dialog-provider";
import ServiceFormPrintDetail from "./service-form-print-detail";
import { ServiceFormPart } from "../../models/service-form-part";

interface ServiceFormDetailProps {
	serviceForm: ServiceForm
}

const renderField = (label: string, value: React.ReactNode) => (
	<Stack spacing={1}>
		<Typography color="#999999" variant="body1">
			{label}
		</Typography>
		<Typography variant="h6" fontWeight={500}>
			{value}
		</Typography>
	</Stack>
);

const ServiceFormDetail: FC<ServiceFormDetailProps> = ({ serviceForm }) => {

	const { handleOpenDialog } = useDialog();

	const handleOpenPrintDialog = () => {
		handleOpenDialog(<ServiceFormPrintDetail serviceForm={serviceForm} />)
	}

	const getPartsCost = (parts: ServiceFormPart[]) => parts.reduce((acc, part) => {
		const quantity = part.quantity || 0; // null ise 0 yap
		const price = part.price || 0;       // null ise 0 yap
		return acc + (quantity * price);
	}, 0)

	return (

		<Grid width="500px" container spacing={1}>
			<Grid item xs={6}>
				{renderField('Ad Soyad', serviceForm.customer.fullName)}
			</Grid>
			<Grid item xs={6}>
				{renderField('E-posta', serviceForm.customer.email)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Telefon', serviceForm.customer.phoneNumber)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Adres', serviceForm.customer.address)}
			</Grid>

			<Grid item xs={6}>
				{renderField('Plaka', serviceForm.vehicle.licensePlate)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Şase Numarası', serviceForm.vehicle.chassisNumber)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Garanti Kapsamı', serviceForm.isWarrant ? "Evet" : "Hayır")}
			</Grid>
			<Grid item xs={6}>
				{renderField('Marka', serviceForm.vehicle.brand)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Model', serviceForm.vehicle.model)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Yıl', serviceForm.vehicle.year)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Renk', serviceForm.vehicle.color)}
			</Grid>

			<Grid item xs={6}>
				{renderField('Servis Tarihi', dayjs(serviceForm.serviceDate).format("DD.MM.YYYY HH:mm"))}
			</Grid>
			<Grid item xs={6}>
				{renderField('Kilometre', serviceForm.mileage)}
			</Grid>
			<Grid item xs={6}>
				<Stack spacing={1}>
					<Typography color="#444" variant="body1">
						Açıklama
					</Typography>
					<Typography variant="body1" fontWeight={500} >
						{serviceForm.customerNotes.split('\n').map((line, index) => (
							<span key={index}>
								{line}
								<br />
							</span>
						))}
					</Typography>
				</Stack>
			</Grid>
			<Grid item xs={6}>
				{renderField('İşçilik Ücreti', `${serviceForm.laborCost || "-"} ₺`)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Parça Ücreti', `${getPartsCost(serviceForm.parts)} ₺`)}
			</Grid>
			<Grid item xs={6}>
				{renderField('Toplam Ücret', `${serviceForm.laborCost + getPartsCost(serviceForm.parts)} ₺`)}
			</Grid>
			<Grid item xs={6}>
				<Stack spacing={1}>
					<Typography color="#444" variant="body1">
						Notlar
					</Typography>
					<Typography variant="body1" fontWeight={500}>
						{serviceForm.notes?.split('\n').join(', ')}
					</Typography>
				</Stack>
			</Grid>
			<Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="contained" onClick={handleOpenPrintDialog}>
					Yazdır
				</Button>
			</Stack>
		</Grid>
	)
}

export default ServiceFormDetail;