import { IconButton, Paper, Stack, Typography } from "@mui/material";
import { pages } from "../global";
import { NavLink, useNavigate } from "react-router-dom";
import '../styles/nav-bar.css';
import Logo from '../assets/logo.png';
import uuid from "react-uuid";
import { useService } from "../provider/service-provider";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Role } from "../enums/role";
import CustomNavLink from "../components/shared/custom-nav-link";

const NavBar = () => {

	const navigate = useNavigate();
	const { authService } = useService();

	const role = localStorage.getItem("role") as Role;

	const handleLogout = () => {
		authService.logout().then(() => {
			navigate("/login")
		});
	}

	return (
		<Stack height="100%" width="300px" flexDirection="column" justifyContent="center" alignItems="center" padding="30px 20px">

			<Stack flex={1} width="100%" gap="15px">

				{/* Logo */}
				<img src={Logo} height="80px" />

				{/* Links */}
				<Stack flex={1} paddingX="20px" width="100%" gap="10px">
					{
						pages.filter(page => role && page.roles.includes(role))
							.map((page) => (
								<CustomNavLink page={page} />
							))
					}
				</Stack>
			</Stack>

			<Stack flexDirection="row" px={2} alignItems="center" justifyContent="space-between" width="100%">
				<Stack>
					<Typography variant="h6" fontSize={15}>{localStorage.getItem("fullName")}</Typography>
					<Typography variant="subtitle1" fontSize={12}>{localStorage.getItem("branch")}</Typography>
				</Stack>
				<IconButton color="primary" onClick={handleLogout}>
					<ExitToAppIcon />
				</IconButton>
			</Stack>

		</Stack>
	);

}

export default NavBar;