import React, { FC, RefObject, useEffect, useRef } from 'react';
import { Typography, Box, Paper, Stack, Divider, TableCell, TableRow, TableHead, Table, TableContainer, TableBody, Grid } from '@mui/material';
import { ServiceForm } from '../../models/service-form.model';
import { ServiceFormPart } from "../../models/service-form-part";
import { useReactToPrint } from 'react-to-print';
import { useDialog } from '../../provider/dialog-provider';
import dayjs from 'dayjs';

interface ServiceFormPrintDetailProps {
  serviceForm: ServiceForm
}

const ServiceFormPrintDetail: FC<ServiceFormPrintDetailProps> = ({ serviceForm}) => {

  const { handleCloseDialog } = useDialog();

  const printComponentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: handleCloseDialog
  });

  //Net tutar 
  const netTotal = serviceForm.parts.reduce(
    (sum, row) => sum + (row.quantity ?? 0) * (row.price ?? 0) * 1.2,
    0
  );

  // %20 KDV'si
  const vatTotal = netTotal * 0.2;

  // Toplam Tutar
  const grandTotal = netTotal + vatTotal;



  useEffect(handlePrint, []);

  return (
    <div ref={printComponentRef}>
      <Stack width="210mm" height="297mm" padding={5} gap={3}>

        <Stack>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
            width="100%"
          >
            <Typography color="black" fontSize={40} fontWeight="bold">
              AKİBAR TİCARET
            </Typography>
          </Box>
        </Stack>
        <Stack>
          <Typography fontSize={35} fontWeight={900} color="#1f3b62">
            SERVİS KABUL FORMU
          </Typography>
        </Stack>

        <Stack direction="row" gap={5} justifyContent="space-between">
          <Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Müşteri Adı Soyadı:</Typography>
              <Typography color="black">{serviceForm.customer.fullName}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Adres:</Typography>
              <Typography color="black">{serviceForm.customer.address}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Tel:</Typography>
              <Typography color="black">{serviceForm.customer.phoneNumber}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">E-mail:</Typography>
              <Typography color="black">{serviceForm.customer.email}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Garanti Kapsamı:</Typography>
              {/* <Typography color="black">{serviceForm.chassisNumber ? "Evet" : "Hayır"}</Typography> */}
            </Stack>
          </Stack>

          <Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Plaka No:</Typography>
              <Typography color="black">{serviceForm.vehicle.licensePlate} - {serviceForm.vehicle.chassisNumber || ""}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Marka:</Typography>
              <Typography color="black">{serviceForm.vehicle.brand}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Model:</Typography>
              <Typography color="black">{serviceForm.vehicle.model}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Km:</Typography>
              <Typography color="black">{serviceForm.mileage}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Aracın Servise Giriş Tarihi:</Typography>
              <Typography color="black">{dayjs(serviceForm.serviceDate).format("DD.MM.YYYY HH:mm")}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" gap={1}>
          <Stack flex={6}>
            <Stack >
              <Typography fontWeight={700} color="white" width="100%" style={{ background: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Müşterinin Belirttiği Hususlar</Typography>
              <Typography color="black" padding={1}>
                {/* {serviceForm.customerNotes.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))} */}
                {serviceForm.customerNotes?.split('\n').join(', ')}
              </Typography>
            </Stack>
          </Stack>
          <Stack flex={2} gap={2}>
            <Stack mb={3}>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Yetkili Servis</Typography>
              <Typography fontWeight={700} color="#1f3b62" width="100%" style={{ textAlign: "center" }}>KAŞE</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" gap={1}>
          <Stack flex={6}>
            <Stack>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Servisin Tespiti ve Yapılan İşlemler</Typography>
            </Stack>

            <Stack>
              <TableContainer component={Paper} style={{ marginTop: 10 }}>
                <Table size='small'>
                  <TableHead style={{ backgroundColor: "#1f3b62" }}>
                    <TableRow>
                      <TableCell ><Typography width="100%" color="white" fontWeight={500}>Açıklama</Typography></TableCell>
                      <TableCell align="right"><Typography color="white" fontWeight={500}>Miktar</Typography></TableCell>
                      <TableCell align="right"><Typography color="white" fontWeight={500}>Fiyat</Typography></TableCell>
                      <TableCell align="right"><Typography color="white" fontWeight={500}>Tutar (KDV Dahil)</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {serviceForm.parts.map((row, index) => (
                      <TableRow key={index} style={{ backgroundColor: 'white', borderBottom: "none" }}>
                        <TableCell style={{borderBottomColor: "#999", color: "black"}}>{row.description}</TableCell>
                        <TableCell style={{borderBottomColor: "#999", color: "black"}} align="right">{row.quantity}</TableCell>
                        <TableCell style={{borderBottomColor: "#999", color: "black"}} align="right">{row.price} ₺</TableCell>
                        <TableCell style={{borderBottomColor: "#999", color: "black"}} align="right">{(row.quantity ?? 0) * (row.price ?? 0) * 1.2} ₺</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" justifyContent="flex-end" spacing={3} style={{ marginTop: 10 }}>
                {/* Metinler Stack'i */}
                <Stack direction="column" spacing={1}>
                  <Typography color="black" style={{ fontSize: "14px", width: "120px", textAlign: "right" }}><strong>Net:</strong></Typography>
                  <Typography color="black" style={{ fontSize: "14px", width: "120px", textAlign: "right" }}><strong>KDV (%20):</strong></Typography>
                  <Typography color="black" style={{ fontSize: "14px", width: "120px", textAlign: "right" }}><strong>Toplam:</strong></Typography>
                </Stack>

                {/* Fiyatlar Stack'i */}
                <Stack direction="column" spacing={1}>
                  <Typography color="black" style={{ fontSize: "14px", textAlign: "right" }}>{netTotal.toFixed(2)} ₺</Typography>
                  <Typography color="black" style={{ fontSize: "14px", textAlign: "right" }}>{vatTotal.toFixed(2)} ₺</Typography>
                  <Typography color="black" style={{ fontSize: "14px", textAlign: "right" }}>{grandTotal.toFixed(2)} ₺</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack >
          <Stack direction="row" gap={1}>
            <Typography fontWeight={700} color="#1f3b62">Aracı Teslim Alan Servis:</Typography>
            <Typography color="black">{serviceForm.user.branch}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={700} color="#1f3b62">Yetkili Kişi:</Typography>
            <Typography color="black">{serviceForm.user.fullName}</Typography>
          </Stack>
        </Stack>
        <Divider style={{ width: '100%', background: '#1f3b62' }} />

        <Stack direction="row" justifyContent="space-between" mt={1} gap={15}>
          <Stack gap={1} style={{ border: '1px solid #1f3b62', borderRadius: '8px', padding: '10px', boxShadow: '2px', flex: '1' }}>
            <Typography fontWeight={500} color="#1f3b62">Servisinize bıraktığım aracımı çalışır vaziyette ve
              eksiksiz olarak teslim aldım.
            </Typography>
            <Typography fontWeight={700} color="#1f3b62" style={{ textAlign: "center" }}>Tarih: {dayjs(serviceForm.serviceDate).format("DD / MM / YYYY")}</Typography>
            <Typography fontWeight={700} color="#1f3b62" mt={1}>
              Müşteri Adı Soyadı:
            </Typography>

            <Typography fontWeight={700} color="#1f3b62" mt={1}>
              Müşteri İmzası:
            </Typography>
          </Stack>


          <Stack gap={1} style={{ border: '1px solid #1f3b62', borderRadius: '8px', padding: '10px', boxShadow: '2px', flex: '1' }}>
            <Typography fontWeight={500} color="#1f3b62">Aracı teslim eden teknisyen, belirtilen hususları kontrol edip müşteriye bilgi vermiştir.
            </Typography>
            <Typography fontWeight={700} color="#1f3b62" mt={2}>
              Yetkili Kişi:
            </Typography>
            <Typography fontWeight={700} color="#1f3b62" mt={1}>
              Yetkili İmzası:
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography color="black" fontWeight={300} style={{ textAlign: "center" }}>Bizleri tercih ettğiniz için teşekkür eder, güvenli sürüşler dileriz.</Typography>
        </Stack>
      </Stack>


    </div>
  );
};

export default ServiceFormPrintDetail;
