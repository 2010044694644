import { IconButton, Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import CustomSearchTextField from "../components/shared/custom-search-textfield";
import { useEffect, useState } from "react";
import { User } from "../models/user";
import CustomAddButton from "../components/shared/custom-add-button";
import { useDialog } from "../provider/dialog-provider";
import AddNewUser from "../components/user-management/add-new-user";
import { NewUser } from "../models/new-user";
import { useService } from "../provider/service-provider";
import CustomDataGrid from "../components/shared/custom-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import CustomConfirmation from "../components/shared/custom-confimation";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import uuid from "react-uuid";
import { Role } from "../enums/role";

const UserManagement = () => {

	const { userService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [searchValue, setSearchValue] = useState("");

	const [users, setUsers] = useState<User[]>([]);
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

	const getAllUsers = () => {
		userService.getAllUsers().then((res) => {
			setUsers(res);
			setFilteredUsers(res);
			setSearchValue("");
		});
	}

	useEffect(getAllUsers, []);

	useEffect(() => {
		setFilteredUsers([...users.filter(user => (
			user.username.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || 
			user.fullName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
		))])
	}, [searchValue]);

	const createUser = (newUser: NewUser) => {
		userService.createUser(newUser).then(() => {
			handleCloseDialog();
			getAllUsers();
		});
	}

	const deleteUser = (username: string) => {
		userService.deleteUser(username).then(() => {
			handleCloseDialog();
			getAllUsers();
		})
	}

	const openNewUserDialog = () => {
		handleOpenDialog(<AddNewUser handleSave={createUser} />, {
			headerTitle: "Yeni Müşteri Ekle",
			haveCloseIcon: true
		});
	}

	const openConfirmationDialog = (username: string) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteUser(username)} subtitle="Bu müşteriye bağlı olan araçlar ve servis formları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const columns: GridColDef[] = [
		{ field: 'fullName', headerName: "Adı Soyadı", width: 200 },
		{ field: 'username', headerName: "Kullanıcı Adı", width: 200 },
		{ field: 'branch', headerName: "Şube", width: 200 },
		{ field: 'role', headerName: "Yetki Sınıfı", width: 200 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					{localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							color="error"
							onClick={() => openConfirmationDialog(params.row.username)}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)}
				</Stack>
			),
		}
	];

	return (
		<Stack>
			<PageHeader title="Kullanıcı Yönetimi" />
			<Stack p="10px">	
				<Stack p="30px" gap="30px" borderRadius="20px" style={{borderWidth: "1px", borderStyle: "solid"}}>
					<Typography fontSize="20px" fontWeight={600}>Tüm Kullanıcılar</Typography>
					<Stack flexDirection="row" justifyContent="space-between">
						<CustomSearchTextField searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Kullanıcı Ara" />
						<CustomAddButton title="Kullanıcı Ekle" onClick={openNewUserDialog} />
					</Stack>
					<CustomDataGrid
						columns={columns}
						rows={filteredUsers}
						disableColumnMenu
						disableColumnResize
						disableRowSelectionOnClick
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10 },
							},
						}}
						getRowId={uuid}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default UserManagement;