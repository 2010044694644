import { Button, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import CustomSearchTextField from "../shared/custom-search-textfield";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useService } from "../../provider/service-provider";
import { Brand } from "../../models/brand";
import { Model } from "../../models/model";
import { Category } from "../../models/category";
import SearchIcon from '@mui/icons-material/Search';
import { Shelf } from "../../models/shelf";
import { StockFilter } from "../../models/stock-filter.model";
import CustomSwitchWithImages from "../shared/custom-switch-with-images ";

interface FilterBarProps {
	handleClickSearch: (isScrapingData: boolean) => void
	stockFilter: {
		searchValue: string | null,
		brandId: number | null,
		modelId: number | null,
		categoryId: number | null,
		shelfId: number | null
	}
	setFieldStockFilter: (fieldName: keyof StockFilter, value: string | number) => void
	isScrapingData: boolean
	setIsScrapingData: Dispatch<SetStateAction<boolean>>
}
const FilterBar: FC<FilterBarProps> = ({ handleClickSearch, stockFilter, setFieldStockFilter, isScrapingData, setIsScrapingData }) => {

	const {palette} = useTheme();
	const { modelService, brandService, categoryService, shelfService } = useService();

	const [brands, setBrands] = useState<Brand[]>([]);
	const [models, setModels] = useState<Model[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [shelfs, setShelfs] = useState<Shelf[]>([]);

	useEffect(() => {
		brandService.getAllBrands().then(setBrands)
		categoryService.getAllCategories().then(setCategories)
		modelService.getAllModels().then(setModels)
		shelfService.getAllShelfs().then(setShelfs)
	}, []);

	useEffect(() => {
		if (stockFilter.brandId)
			modelService.getModelsByBrandId(stockFilter.brandId).then(setModels)
		else
			modelService.getAllModels().then(setModels)
	}, [stockFilter.brandId]);

	return (
		<Stack flexDirection="row" gap="10px">
			<CustomSearchTextField searchValue={stockFilter.searchValue} setSearchValue={(value) => setFieldStockFilter("searchValue", value)} placeholder="Kayıt Ara" />

			<Stack position="relative">
				<Typography position="absolute" fontSize={12} top={-8} left={8} px={0.2} zIndex={2} sx={{ backgroundColor: palette.mode == 'dark' ? '#292929': "white" }} >Marka</Typography>
				<Select
					value={stockFilter.brandId || ""}
					onChange={(e) => setFieldStockFilter("brandId", Number(e.target.value))}
					sx={{ width: "150px" }}
				>
					<MenuItem>
						<Typography color="gray">Tümü</Typography>
					</MenuItem>
					{
						brands.map((brand) => (
							<MenuItem value={brand.id}>{brand.name}</MenuItem>
						))
					}
				</Select>
			</Stack>

			<Stack position="relative">
				<Typography position="absolute" fontSize={12} top={-8} left={8} px={0.2} zIndex={2} sx={{ backgroundColor: palette.mode == 'dark' ? '#292929': "white"  }} >Model</Typography>
				<Select
					value={stockFilter.modelId || ""}
					onChange={(e) => setFieldStockFilter("modelId", e.target.value as number)}
					sx={{ width: "150px" }}
				>
					<MenuItem>
						<Typography color="gray">Tümü</Typography>
					</MenuItem>
					{
						models.map((model) => (
							<MenuItem value={model.id}>{model.name}</MenuItem>
						))
					}
				</Select>
			</Stack>

			<Stack position="relative">
				<Typography position="absolute" fontSize={12} top={-8} left={8} px={0.2} zIndex={2} sx={{ backgroundColor: palette.mode == 'dark' ? '#292929': "white"  }} >Kategori</Typography>
				<Select
					value={stockFilter.categoryId || ""}
					onChange={(e) => setFieldStockFilter("categoryId", e.target.value as number)}
					sx={{ width: "150px" }}
					disabled={isScrapingData}
				>
					<MenuItem>
						<Typography color="gray">Tümü</Typography>
					</MenuItem>
					{
						categories.map((category) => (
							<MenuItem value={category.id}>{category.name}</MenuItem>
						))
					}
				</Select>
			</Stack>

			<Stack position="relative">
				<Typography position="absolute" fontSize={12} top={-8} left={8} px={0.2} zIndex={2} sx={{ backgroundColor: palette.mode == 'dark' ? '#292929': "white"  }} >Raf</Typography>
				<Select
					value={stockFilter.shelfId || ""}
					onChange={(e) => setFieldStockFilter("shelfId", e.target.value as number)}
					sx={{ width: "150px" }}
					disabled={isScrapingData}
				>
					<MenuItem>
						<Typography color="gray">Tümü</Typography>
					</MenuItem>
					{
						shelfs.map((shelf) => (
							<MenuItem value={shelf.id}>{shelf.name}</MenuItem>
						))
					}
				</Select>
			</Stack>

			<CustomSwitchWithImages checked={isScrapingData} onChange={(e) => setIsScrapingData(e.target.checked)} />

			<Button
				startIcon={<SearchIcon />}
				sx={{ width: "120px" }}
				variant="contained"
				onClick={() => handleClickSearch(isScrapingData)}
			>
				Ara
			</Button>
		</Stack>
	);
}

export default FilterBar;