import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { ApiProvider } from './provider/api-provider';
import { ServiceProvider } from './provider/service-provider';
import CustomCircularProgress from './components/shared/custom-circular-progress';
import { DialogProvider } from './provider/dialog-provider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ThemeProviderWrapper from './provider/theme-provider-wrapper';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<ThemeProviderWrapper>
			<HashRouter>
				<ApiProvider>
					<ServiceProvider>
						<DialogProvider>
							<CustomCircularProgress />
							<App />
						</DialogProvider>
					</ServiceProvider>
				</ApiProvider>
			</HashRouter>
		</ThemeProviderWrapper>
	</LocalizationProvider>
);
